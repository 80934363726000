"use client";
import { getCookie, setCookie } from "cookies-next";
import Link from "next/link";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const CoockiesComp = () => {
  const [showCoockies, setShowCoockies] = useState(false);
  useEffect(() => {
    if (!getCookie("uuid")) {
      setShowCoockies(true);
    }
    if (!getCookie("visitor")) {
      setVisitedUser();
    }
  }, []);
  const setVisitedUser = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/half-hourly-visitor"
      );
      await res.json();
      setCookie("visitor", uuidv4(), {
        path: "/",
        maxAge: 1800, // Expires after 1hr
        sameSite: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const setTheCoockies = () => {
    setCookie("uuid", uuidv4(), {
      path: "/",
      maxAge: 3600 * 24 * 365, // Expires after 1hr
      sameSite: true,
    });
    setShowCoockies(false);
  };
  return (
    showCoockies && (
      <div
        className="text-center"
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          background: "#fff",
          padding: "15px",
          borderTop: "1px solid #B71D20",
          zIndex: "200",
        }}
      >
        <p className="m-0">
          <b>Do you like cookies?</b> 🍪 We use cookies to ensure you get the
          best experience on our website.{" "}
          <Link
            title="Learn more..."
            href={"/info/privacy-policy"}
            target="_blank"
            className="pr-2"
          >
            <strong>Learn more...</strong>
          </Link>
          <button
            type="button"
            className="btn btn-primary-light btn-sm ml-5"
            onClick={setTheCoockies}
          >
            I agree
          </button>
        </p>
      </div>
    )
  );
};

export default CoockiesComp;
