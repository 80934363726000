"use client";
import StylesIcon from "@/styles/Icons.module.css";
const BackToTop = () => {
  return (
    <div id="back-to-top">
      <a href="#top" aria-label="Go to top">
        <i
          className={`${StylesIcon.chevron__up__icon}`}
          style={{ color: "#fff" }}
        ></i>
      </a>
    </div>
  );
};
export default BackToTop;
