import React from "react";

import useGoogleIdentify from "./useGoogleIdentify";
const OneTabLogin = () => {
  const nextAuthOpt = {
    redirect: true,
    callbackUrl: "/user/dashboard",
  };
  const googleOpt = {
    prompt_parent_id: "oneTap",
    isOneTap: true,
  };

  const { isSignedIn } = useGoogleIdentify({
    nextAuthOpt,
    googleOpt,
  });
  return (
    <>
      {!isSignedIn ? (
        <div
          id="oneTap"
          style={{ position: "fixed", right: "0", zIndex: "999" }}
        />
      ) : null}
    </>
  );
};

export default OneTabLogin;
