"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { SessionProvider } from "next-auth/react";
import OneTabLogin from "./OneTabLogin";
import { Suspense } from "react";

const ProvidersProgressBar = ({ children }) => {
  return (
    <>
      <SessionProvider>
        <OneTabLogin />
        {children}
      </SessionProvider>
      <Suspense fallback={""}>
        <ProgressBar
          height="4px"
          color="#fff"
          options={{ showSpinner: true }}
          shallowRouting
        />
      </Suspense>
    </>
  );
};

export default ProvidersProgressBar;
