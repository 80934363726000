"use client";
import Image from "next/image";
import Link from "next/link";

const AllImageSettings = ({
  type,
  height,
  width,
  height2,
  width2,
  maxHeight,
  item,
  image,
  alt,
  viewYoutube,
  src,
  imageCaption,
  padding,
  adsLink,
}) => {
  const nullOrUndefinedCheck = (variable) => {
    if (variable === undefined || variable === null) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      {type == "logo" ? (
        <Image
          height={height}
          width={width}
          className="logo__img"
          src={process.env.REACT_APP_BASE_URL + "/logo.png"}
          alt="TBN24 Logo "
          quality={100}
          priority
          style={{
            width: width2,
            maxWidth: "100%",
            height: height2,
            maxHeight: maxHeight,
          }}
        />
      ) : type == "footerApps" ? (
        nullOrUndefinedCheck(image) ? (
          <Image
            height={250}
            width={110}
            className="logo__img"
            src={image}
            alt={alt}
            quality={100}
            style={{
              width: "160px",
              maxWidth: "100%",
              height: "auto",
              maxHeight: "85px",
            }}
          />
        ) : (
          ""
        )
      ) : type == "bigImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            alt={item.image_caption ? item.image_caption : item.title}
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            width={1200}
            height={630}
            quality={100}
            itemProp="photo"
            sizes="(max-width: 412px) 96vw, (max-width: 992px) 45vw, (max-width: 1200px) 43vw, 35vw"
            style={{ width: "100%", height: "auto" }}
            className="page-image "
            blurDataURL="URL"
            placeholder="blur"
          />
        ) : (
          ""
        )
      ) : type == "singleImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            alt={item.image_caption ? item.image_caption : item.title}
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            width={1200}
            height={630}
            quality={100}
            itemProp="photo"
            sizes="(max-width: 412px) 96vw, (max-width: 992px) 23vw, (max-width: 1200px) 22vw, 20vw"
            style={{ width: "100%", height: "auto" }}
            className="page-image "
            blurDataURL="URL"
            placeholder="blur"
          />
        ) : (
          ""
        )
      ) : type == "singleImageProgram" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            alt={item.image_caption ? item.image_caption : item.name}
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            width={1200}
            height={630}
            quality={100}
            itemProp="photo"
            sizes="(max-width: 412px) 96vw, (max-width: 992px) 23vw, (max-width: 1200px) 22vw, 20vw"
            style={{ width: "100%", height: "auto" }}
            className="page-image "
            blurDataURL="URL"
            placeholder="blur"
          />
        ) : (
          ""
        )
      ) : type == "detailsImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            priority
            width={1200}
            height={630}
            quality={100}
            alt={item.image_caption ? item.image_caption : item.title}
            loading="eager"
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            sizes="(max-width: 768px) 96vw, (max-width: 1200px) 60vw, 42vw"
            style={{
              width: "100%",
              height: "auto",
            }}
            className="page-image"
          />
        ) : (
          ""
        )
      ) : type == "smallImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            alt={item.image_caption ? item.image_caption : item.title}
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            width={1200}
            height={630}
            quality={100}
            itemProp="photo"
            sizes="(max-width: 412px) 46vw, (max-width: 992px) 20vw, (max-width: 1200px) 19vw, 18vw"
            className="page-image page-image-small "
            blurDataURL="URL"
            placeholder="blur"
          />
        ) : (
          ""
        )
      ) : type == "titleImage" ? (
        nullOrUndefinedCheck(src) ? (
          <Image
            quality={75}
            alt={alt}
            src={src}
            width={1200}
            height={630}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            style={{
              cursor: "pointer",
              width: "100%",
              height: "210px",
              objectFit: "cover",
            }}
            className="page-image "
          />
        ) : (
          ""
        )
      ) : type == "topImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            alt={item.image_caption ? item.image_caption : item.title}
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            width={1200}
            height={630}
            quality={100}
            sizes="(max-width: 412px) 48vw, (max-width: 992px) 10vw, (max-width: 1200px) 10vw, 10vw"
            className="page-image page-image-small "
            blurDataURL="URL"
            placeholder="blur"
            style={{
              width: "100%",
              height: "auto",
              cursor: "pointer",
              minHeight: "85px",
              maxHeight: "90px",
              objectFit: "cover",
            }}
          />
        ) : (
          ""
        )
      ) : type == "youtubeIcon" ? (
        <Image
          src={process.env.REACT_APP_BASE_URL + "/youtubeicon.png"}
          alt="youtubeicon"
          height={55}
          width={55}
          quality={100}
          style={{
            position: "absolute",
            zIndex: "1 !important",
            left: "40%",
            top: "15%",
            height: "55px",
            width: "55px",
            cursor: "pointer",
          }}
          onClick={() =>
            viewYoutube(
              item.video_links ? item.video_links : item.video,
              item.title,
              item.description
            )
          }
        />
      ) : type == "youtubeIconTwo" ? (
        <Image
          src={process.env.REACT_APP_BASE_URL + "/youtubeicon2.png"}
          width={50}
          height={50}
          quality={100}
          alt="youtubeicon"
          className="youtubeicon "
        />
      ) : type == "helplineImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            quality={100}
            alt={item.name}
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            width={0}
            height={0}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            style={{
              cursor: "pointer",
              width: "100%",
              height: "120px",
              objectFit: "contain",
            }}
            className="page-image-small-30 "
          />
        ) : (
          ""
        )
      ) : type == "helplineSingleImage" ? (
        nullOrUndefinedCheck(item.image) ||
        nullOrUndefinedCheck(item.image_url) ? (
          <Image
            src={
              item.image_url
                ? item.image_url
                : item.image
                ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                : ""
            }
            alt={item.image_caption ? item.image_caption : item.name}
            width={500}
            height={500}
            quality={100}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 33vw, 33vw"
            style={{
              cursor: "pointer",
              width: "100%",
              maxWidth: "500px",
              height: "auto",
            }}
            className="page-image "
          />
        ) : (
          ""
        )
      ) : type == "AdImgCenter" ? (
        <div className="row">
          {nullOrUndefinedCheck(image) ? (
            <Link
              href={adsLink ? adsLink : "#"}
              target={adsLink ? "_blank" : ""}
              className="w-100"
            >
              <Image
                alt={imageCaption ? imageCaption : "ads"}
                src={image ? image : ""}
                width={1177}
                height={75}
                quality={60}
                sizes="98vw"
                priority
                blurDataURL="URL"
                placeholder="blur"
                loading="eager"
                style={{
                  width: width,
                  height: height,
                  objectFit: "cover",
                  margin: padding,
                }}
              />
            </Link>
          ) : (
            ""
          )}
        </div>
      ) : type == "AdImgCenterTwo" ? (
        nullOrUndefinedCheck(image) ? (
          <Link
            href={adsLink ? adsLink : "#"}
            target={adsLink ? "_blank" : ""}
            className="w-100"
          >
            <Image
              priority
              alt={imageCaption ? imageCaption : "ads"}
              src={image ? image : ""}
              width={1000}
              height={60}
              quality={60}
              loading="eager"
              sizes="(max-width: 768px) 98vw, (max-width: 1200px) 75vw, 70vw"
              style={{
                width: width,
                height: height,
                objectFit: "cover",
                padding: padding,
                borderRadius: "0px",
              }}
            />
          </Link>
        ) : (
          ""
        )
      ) : type == "AdImgSidebar" ? (
        nullOrUndefinedCheck(image) ? (
          <Link
            href={adsLink ? adsLink : "#"}
            target={adsLink ? "_blank" : ""}
            className="w-100"
          >
            <Image
              alt={imageCaption ? imageCaption : "ads"}
              src={image ? image : ""}
              width={0}
              height={0}
              quality={100}
              blurDataURL="URL"
              placeholder="blur"
              sizes="100vw"
              style={{
                width: width,
                height: height,
                borderRadius: "6px",
                objectFit: "contain",
                padding: padding,
                marginBottom: "15px",
              }}
            />
          </Link>
        ) : (
          ""
        )
      ) : type == "avatarImage" ? (
        <Image
          alt="avatar"
          src={process.env.REACT_APP_BASE_URL + "/avatar.jpg"}
          height={"0"}
          width={"0"}
          sizes="5vw"
          style={{
            width: "50px",
            height: "50px",
            paddingRight: "15px",
          }}
        />
      ) : type == "deskImage" ? (
        <Image
          quality={100}
          priority
          alt="TBN24 logo"
          src={process.env.REACT_APP_BASE_URL + "/logo-square.png"}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          height={100}
          width={100}
          itemProp="photo"
          className="desk-logo  "
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AllImageSettings;
