"use client";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

const Clock = () => {
  
  const [clock, setClock] = useState("");

  useEffect(() => {
    
    const timerId = setInterval(refreshClock, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);
  const refreshClock = () => {
    setClock(moment.utc().local().format("MMMM Do YYYY, H:mm"));
  };

  return <span>{clock}</span>;
};

export default Clock;
