"use client";
import Styles from "@/styles/Header.module.css";
import Link from "next/link";
import { useSession, signOut } from "next-auth/react";
const LoginResiterLink = () => {
  const { data: session, status } = useSession();
  return (
    <ul className={Styles.loginLink}>
      {status == "authenticated" ? (
        <>
          <li>
            <Link href={"/user/dashboard"} style={{ cursor: "pointer" }}>
              {session?.user?.name.substring(0, 10)}...
            </Link>
          </li>
          <li>
            <Link
              onClick={() =>
                signOut({
                  callbackUrl: `${process.env.REACT_APP_BASE_URL}`,
                })
              }
              href={"#"}
              style={{ cursor: "pointer" }}
            >
              লগ আউট
            </Link>
          </li>
        </>
      ) : (
        <>
          <li>
            <Link href={"/login"} style={{ cursor: "pointer" }}>
              লগইন
            </Link>
          </li>
          <li>
            <Link href={"/register"} style={{ cursor: "pointer" }}>
              রেজিস্ট্রেশন
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};

export default LoginResiterLink;
