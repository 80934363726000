"use client";
import { useEffect, useState } from "react";
import Link from "next/link";
import Marquee from "react-fast-marquee";

const FlashNewsData = () => {
  
  const [data, setData] = useState([]);
  useEffect(() => {
    
    getDataFlashNews();
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);
  const getDataFlashNews = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/flash-news/list"
      );
      setData(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  const listenToScroll = () => {
    let heightToHideFrom = 60;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      const myElement = document.getElementById("flashNews");
      myElement.style.display = "none";
    } else {
      const myElement = document.getElementById("flashNews");
      myElement.style.display = "flex";
    }
  };
  return (
   
    data && (
      <div style={{ display: "flex" }}>
        <Marquee
          speed={30}
          gradientColor={false}
          pauseOnHover="pause"
          className="newsticker__item"
        >
          {data.map((item, index) => (
            <Link
              href={"/news/" + item.slug}
              className="newsticker__item-url"
              key={index}
              prefetch={false}
            >
              {item.title} ।
            </Link>
          ))}
        </Marquee>
      </div>
    )
  );
};

export default FlashNewsData;
