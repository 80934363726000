"use client";
import { useEffect, useState } from "react";
import SideNavbar from "../sidebar/SideNavbar";
import Styles from "@/styles/Header.module.css";

const OpenSidebar = () => {
  
  const [sideBar, setSiderBar] = useState(false);
  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/categories/header/list",
        { next: { revalidate: 86400 } }
      );
      setHeaderData(await res.json());
    } catch (error) {
      console.log(error);
    }
  };

  const onShowHandeler = () => {
    setSiderBar(true);
  };
  const onCloseHandeler = () => {
    sideBar ? setSiderBar(false) : "";
  };
  return (
    (
      <>
        <div
          className={`${Styles.nav__icon__toggle}`}
          onClick={onShowHandeler}
          title="Menus"
          style={{ float: "left", color: "#fff" }}
        >
          <div className={`${Styles.nav__icon__toggle__box}`}>
            <div
              className={`  ${Styles.nav__icon__toggle__inner}`}
              style={{ color: "#fff" }}
            ></div>
          </div>
        </div>
        {sideBar && (
          <>
            <SideNavbar
              headerData={headerData}
              onCloseHandeler={onCloseHandeler}
            />
            
            <div className="overlay-div" onClick={onCloseHandeler}></div>
       
          </>
        )}
      </>
    )
  );
};

export default OpenSidebar;
