"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";

const HeaderItemTop = () => {
  const asPath = usePathname();

  return (
    <>
      <li>
        <Link
          href={"/"}
          title={"হোম"}
          className={"/" === asPath ? "active" : ""}
          prefetch={false}
        >
          হোম
        </Link>
      </li>
      <li>
        <Link
          href={"/videos"}
          title={"ভিডিও"}
          className={"/videos" === asPath ? "active" : ""}
          prefetch={false}
        >
          ভিডিও
        </Link>
      </li>
      <li>
        <Link
          href={"/photos"}
          title={"ফটো"}
          className={"/photos" === asPath ? "active" : ""}
          prefetch={false}
        >
          ফটো
        </Link>
      </li>
      <li>
        <Link
          href={"/opinion"}
          title={"মতামত"}
          className={"/opinion" === asPath ? "active" : ""}
          prefetch={false}
        >
          মতামত
        </Link>
      </li>
      {/* <li>
        <Link
          href={"/advertisement"}
          title={"বিজ্ঞাপন"}
          className={"/advertisement" === asPath ? "active" : ""}
          prefetch={false}
        >
          বিজ্ঞাপন
        </Link>
      </li> */}
      <li>
        <Link
          href={"/programs"}
          title={"আমাদের প্রোগ্রাম"}
          className={"/programs" === asPath ? "active" : ""}
          prefetch={false}
        >
          আমাদের প্রোগ্রাম
        </Link>
      </li>
      <li>
        <Link
          href={"/contact"}
          title={"যোগাযোগ"}
          className={"/contact" === asPath ? "active" : ""}
          prefetch={false}
        >
          যোগাযোগ
        </Link>
      </li>
      <li>
        <Link
          href={"/helpline"}
          title={"হেল্প লাইন"}
          className={"/helpline" === asPath ? "active" : ""}
        >
          হেল্প লাইন
        </Link>
      </li>
      <li>
        <Link
          href={"https://election.tbn24.com/"}
          title={"নির্বাচন-২০২৪"}
          target="_blank"
        >
          নির্বাচন-২০২৪
        </Link>
      </li>
       <li
        style={{
          float: "right",
          background: "rgb(228 37 37)",
        }}
      >
        <Link
          href={"/live-tv"}
          className={"/live-tv" === asPath ? "active" : ""}
          title={"লাইভ টিভি"}
          style={{ fontWeight: "900", color: "white" }}
          onMouseEnter={(e) =>
            (e.target.style.backgroundColor = "rgb(90 10 10)")
          }
          onMouseLeave={(e) =>
            (e.target.style.backgroundColor = "rgb(228 37 37)")
          }
        >
          লাইভ টিভি
        </Link>
      </li> 
    </>
  );
};

export default HeaderItemTop;
