"use client";
import { useEffect, useState } from "react";
import { useSession, signIn } from "next-auth/react";

const useGoogleIdentify = (props) => {
  const url = "https://accounts.google.com/gsi/client";
  const { data: session, status } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);

  const { nextAuthOpt, googleOpt } = props || {};

  useEffect(() => {
    if (status === "loading") return;
    if (session) {
      setIsSignedIn(true);
      setIsGoogleInitialized(true);
    }
  }, [session, status]);

  useEffect(() => {
    if (isScriptLoaded) return;
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [isScriptLoaded]);

  useEffect(() => {
    if (!isScriptLoaded || isGoogleInitialized || status === "loading") return;

    const { google } = window;
    if (google) {
      google.accounts.id.initialize({
        client_id: process.env.GOOGLE_CLIENT_ID,
        callback: async (response) => {
          setIsLoading(true);
          await signIn("onetabauth", {
            credential: response.credential,
            redirect: false,
            ...nextAuthOpt,
          });
          setIsLoading(false);
        },
        ...googleOpt,
      });

      setIsGoogleInitialized(true);

      // Show One Tap prompt only if user is not signed in
      if (googleOpt?.isOneTap && !session) {
        google.accounts.id.prompt();
      }
    }
  }, [isScriptLoaded, session, googleOpt, nextAuthOpt, isGoogleInitialized]);

  return { isLoading, isSignedIn };
};

export default useGoogleIdentify;
