"use client";
import { useEffect, useState } from "react";
import Link from "next/link";
import StylesSidebar from "@/styles/Search.module.css";
import StylesIcon from "@/styles/Icons.module.css";
import { useRouter } from "next/navigation";
import { useSession, signOut } from "next-auth/react";

const SideNavbar = ({ onCloseHandeler }) => {
  const { data: session, status } = useSession();

  const router = useRouter();
  const [keyword, setKeyword] = useState("");
  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/categories/header/list",
        { next: { revalidate: 86400 } }
      );
      setHeaderData(await res.json());
    } catch (error) {
      console.log(error);
    }
  };
  const searchHandelSubmit = (e) => {
    e.preventDefault();
    if (keyword) {
      onCloseHandeler();
      return router.push("/search?q=" + keyword);
    }
  };
  return (
    
    <header className="sidenav sidenav--is-open" id="sidenav">
      <div className="sidenav__close" onClick={onCloseHandeler}>
        <button className="sidenav__close-button" aria-label="closebtn">
          <i className={`${StylesIcon.closed__icon}`}></i>
        </button>
      </div>

      <div className="flex-child nav__right">
        <form
          onSubmit={searchHandelSubmit}
          className={`${StylesSidebar.side_nav__search}`}
        >
          <input
            type={"text"}
            className={`${StylesSidebar.header__search__input}`}
            placeholder="নিউজ খুঁজুন"
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button
            type="submit"
            aria-label="searchbtn2"
            className={`${StylesSidebar.sidenav__search__icon__btn}`}
            style={{ padding: "3px 6px", top: "12px" }}
          >
            <i
              className={`${StylesIcon.search__icon}`}
              style={{ color: "#fff", marginTop: "6px" }}
            ></i>
          </button>
        </form>
      </div>

      <nav className="sidenav__menu-container-fluid">
        <ul className="sidenav__menu" role="menubar" onClick={onCloseHandeler}>
          <li>
            <Link
              href={"/"}
              title="হোম"
              className="sidenav__menu-url"
              prefetch={false}
            >
              হোম
            </Link>
          </li>
          
          <li>
            <Link
              href={"/news"}
              title="সবশেষ"
              className="sidenav__menu-url"
              prefetch={false}
            >
              সবশেষ
            </Link>
          </li>

          {headerData.length > 0 &&
            headerData.map((item, index) => (
              <li key={index}>
                <Link
                  href={"/news/category/" + item.slug}
                  title={item.name}
                  key={index}
                  className="sidenav__menu-url"
                  prefetch={false}
                >
                  {item.name}
                </Link>
              </li>
            ))}

          <li>
            <Link
              href={"/videos"}
              title="ভিডিও"
              className="sidenav__menu-url"
              prefetch={false}
            >
              ভিডিও
            </Link>
          </li>
          <li>
            <Link
              href={"/photos"}
              title="ফটো"
              className="sidenav__menu-url"
              prefetch={false}
            >
              ফটো
            </Link>
          </li>
          <li>
            <Link
              href={"/opinion"}
              title="মতামত"
              className="sidenav__menu-url"
              prefetch={false}
            >
              মতামত
            </Link>
          </li>
          <li>
            <Link
              href={"/advertisement"}
              title="বিজ্ঞাপন"
              className="sidenav__menu-url"
              prefetch={false}
            >
              বিজ্ঞাপন
            </Link>
          </li>
          <li>
            <Link
              href={"/programs"}
              title="আমাদের প্রোগ্রাম"
              className="sidenav__menu-url"
              prefetch={false}
            >
              আমাদের প্রোগ্রাম
            </Link>
          </li>
          <li>
            <Link
              href={"/info/about"}
              title="  আমাদের সম্পর্কে"
              className="sidenav__menu-url"
              prefetch={false}
            >
              আমাদের সম্পর্কে
            </Link>
          </li>
          <li>
            <Link
              href={"/contact"}
              title="যোগাযোগ"
              className="sidenav__menu-url"
              prefetch={false}
            >
              যোগাযোগ
            </Link>
          </li>
          <li>
            <Link
              href={"/helpline"}
              title="হেল্প লাইন"
              className="sidenav__menu-url"
            >
              হেল্প লাইন
            </Link>
          </li> <li>
            <Link
              href={"/live-tv"}
              title={"লাইভ টিভি"}
              className="sidenav__menu-url"
            >
              লাইভ টিভি
            </Link>
          </li> 
          <li>
            <Link
              href={"https://election.tbn24.com/"}
              title={"নির্বাচন-২০২৪"}
              target="_blank"
              className="sidenav__menu-url"
            >
              নির্বাচন-২০২৪
            </Link>
          </li>
          {status == "authenticated" ? (
            <>
              <li>
                <Link
                  href={"/user/dashboard"}
                  style={{ cursor: "pointer" }}
                  className="sidenav__menu-url"
                >
                  {session?.user?.name}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    signOut({
                      callbackUrl: `${process.env.REACT_APP_BASE_URL}`,
                    })
                  }
                  href={"#"}
                  style={{ cursor: "pointer" }}
                  className="sidenav__menu-url"
                >
                  লগ আউট
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  href={"/login"}
                  style={{ cursor: "pointer" }}
                  className="sidenav__menu-url"
                >
                  লগ ইন
                </Link>
              </li>
              <li>
                <Link
                  href={"/register"}
                  style={{ cursor: "pointer" }}
                  className="sidenav__menu-url"
                >
                  রেজিস্ট্রেশন
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>

      <div className="socials sidenav__socials">
        <Link
          href={"https://www.facebook.com/tbn24usa"}
          className={`social social-facebook`}
          target="_blank"
          prefetch={false}
        >
          <i
            className={`${StylesIcon.facebook__icon}`}
            style={{ color: "#fff" }}
          ></i>
        </Link>
        <Link
          href={"https://twitter.com/tbn24usa"}
          className={`social social-twitter`}
          target="_blank"
          prefetch={false}
        >
          <i
            className={`${StylesIcon.twitter__icon}`}
            style={{ color: "#fff" }}
          ></i>
        </Link>
        <Link
          href={"https://www.instagram.com/tbn24ny/"}
          className={`social social-instagram`}
          target="_blank"
          prefetch={false}
        >
          <i
            className={`${StylesIcon.instagram__icon}`}
            style={{ color: "#fff" }}
          ></i>
        </Link>
        <Link
          href={"https://www.youtube.com/tbn24usa"}
          className={`social social-youtube`}
          target="_blank"
          prefetch={false}
        >
          <i
            className={`${StylesIcon.youtube__icon}`}
            style={{ color: "#fff" }}
          ></i>
        </Link>
      </div>
    </header>
  );
};

export default SideNavbar;
