"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

const HeaderItemBottom = () => {
  const asPath = usePathname();
  
  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/categories/header/list",
        { next: { revalidate: 86400 } }
      );
      setHeaderData(await res.json());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    (
      <>
        <li>
          <Link
            href={"/news"}
            title={"সবশেষ"}
            className={"/news" === asPath ? "active" : ""}
            prefetch={false}
          >
            সবশেষ
          </Link>
        </li>
        {headerData &&
          headerData.map((item, index) => (
            <li key={index}>
              <Link
                href={"/news/category/" + item.slug}
                title={item.name}
                className={
                  "/news/category/" + item.slug === asPath ? "active" : ""
                }
                prefetch={false}
              >
                {item.name}
              </Link>
            </li>
          ))}
      </>
    )
  );
};

export default HeaderItemBottom;
