import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\AllImageSettings.jsx");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\back-to-top\\BackToTop.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\CoockiesComp.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\flash-news\\Clock.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\flash-news\\FlashNewsData.jsx");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\footer\\Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\header\\HeaderItemBottom.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\header\\HeaderItemTop.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\header\\HeaderSearh.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\header\\OpenSidebar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\components\\ProvidersProgressBar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\next\\dist\\client\\app-dir\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-chunks.js");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\next\\font\\local\\target.css?{\"path\":\"app\\\\layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/tbn24-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\",\"display\":\"swap\"},{\"path\":\"../fonts/tbn24-600.woff2\",\"weight\":\"600\",\"style\":\"normal\",\"display\":\"swap\"}]}],\"variableName\":\"tbn24_fonts\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\react-toastify\\dist\\react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\styles\\globals.css");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\styles\\Header.module.css");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\styles\\Icons.module.css");
;
import(/* webpackMode: "eager" */ "C:\\xampp\\htdocs\\tbn24-news-portal-nextjs-15\\node_modules\\react-toastify\\dist\\ReactToastify.css");
