"use client";
import StylesSearch from "@/styles/Search.module.css";
import StylesIcon from "@/styles/Icons.module.css";
import { useRouter } from "next/navigation";
import { useState } from "react";
import LoginResiterLink from "./LoginResiterLink";
const HeaderSearh = () => {
  const router = useRouter();
  const [keyword, setKeyword] = useState("");

  const searchHandelSubmit = (e) => {
    e.preventDefault();
    if (keyword) {
      return router.push("/search?q=" + keyword);
    }
  };
  return (
    <>
      {" "}
      <form
        onSubmit={searchHandelSubmit}
        className="flex-child nav__right-item nav__search"
      >
        <input
          type={"text"}
          className={` ${StylesSearch.header__search__input} mb-0`}
          placeholder="নিউজ খুঁজুন"
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button
          type="submit"
          aria-label="searchbtn"
          title="Search Now"
          className={`${StylesSearch.header__search__icon__btn}`}
        >
          <i
            className={`${StylesIcon.search__icon}`}
            style={{ color: "#fff", marginTop: "5px" }}
          ></i>
        </button>
      </form>
      <LoginResiterLink />
    </>
  );
};

export default HeaderSearh;
