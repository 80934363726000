"use client";
import Styles from "@/styles/Footer.module.css";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
const AllImageSettings = dynamic(() => import("@/components/AllImageSettings"));
const FooterDynamic = dynamic(() => import("./FooterDynamic"));
const FooterStaticMenu = dynamic(() => import("./FooterStaticMenu"));
const FooterSocial = dynamic(() => import("./FooterSocial"));
const FooterApps = dynamic(() => import("./FooterApps"));
const FooterBottom = dynamic(() => import("./FooterBottom"));

var finalEnglishToBanglaNumber = {
  0: "০",
  1: "১",
  2: "২",
  3: "৩",
  4: "৪",
  5: "৫",
  6: "৬",
  7: "৭",
  8: "৮",
  9: "৯",
};

String.prototype.getDigitBanglaFromEnglish = function () {
  var retStr = this;
  for (var x in finalEnglishToBanglaNumber) {
    retStr = retStr.replace(new RegExp(x, "g"), finalEnglishToBanglaNumber[x]);
  }
  return retStr;
};

const Footer = () => {
  const [dataFooter, setDataFooter] = useState([]);
  useEffect(() => {
    getFooterData();
  }, []);
  const getFooterData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/categories/footer/list",
        { next: { revalidate: 86400 } }
      );
      const resData = await res.json();
      setDataFooter(resData);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <footer className={`${Styles.footer} ${Styles.footerDark}`}>
        <div className={"container-fluid"}>
          <div className="row justify-content-between align-items-center mb-5 pt-4 ">
            <div className="col-md-8 text-sm-center">
              <Link href="/">
                <AllImageSettings
                  type="logo"
                  height={250}
                  width={110}
                  height2={"auto"}
                  width2={"200px"}
                  maxHeight={"120px"}
                />
              </Link>
            </div>

            <FooterSocial />
          </div>

          <div className="row pb-4">
            <div className="col-lg-3 text-sm-center">
              <div
                className={`${Styles.widget} ${Styles.widget_nav_menu} mb-5`}
              >
                <h4 className={`${Styles.widget__title} text-sm-center`}>
                  মেনু
                </h4>
                <FooterStaticMenu />
              </div>
            </div>

            <FooterDynamic dataFooter={dataFooter} />
            <FooterApps />
          </div>
        </div>

        <FooterBottom />
      </footer>
    </>
  );
};
export default Footer;
